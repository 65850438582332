@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url("https://fonts.googleapis.com/css?family=Karla:400,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bakbak+One&display=swap");
@import url("https://rsms.me/inter/inter.css");

html,
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
* {
  margin: 0;
  padding: 0;
}

.gradient {
  background: rgb(190, 205, 214);
  background: linear-gradient(
    -225deg,
    rgb(47, 140, 228) 0%,
    rgb(15, 247, 7) 100%
  );
}

.button-submit {
  background-image: linear-gradient(300deg, #6ad4e7 0%, #44a9bb 90%);
  float: right;
  color: white;
  font-weight: bold;
}

input {
  outline: none;
}

textarea {
  outline: none;
}

input:focus {
  box-shadow: 0px 0px 0px 1px #55afd3;
}

textarea:focus {
  box-shadow: 0px 0px 0px 1px #55afd3;
}

/* Scroll bar */

* {
  scrollbar-width: thin;
  scrollbar-color: transparent;
}

*::-webkit-scrollbar {
  width: 7px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #807f7f; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  width: 7px;
  max-width: 7px;
}

.select-default {
  border-radius: 0.25rem;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.input-disabled{
  background-color: #f5f5f5 !important;
  color: #8b8a8a !important;
}

.input-disabled:focus{
  border-color: #d9d9d9 !important;
}